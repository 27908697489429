import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class ApiService {

    private API_URL = '/api/v1/contact';

    constructor(protected http: HttpClient){}

    postContactForm(data: any): Observable<String> {
        let payload = {
            email: data.email,
            message: data.message,
            name: data.name,
            subject: data.subject,
        };

        let headers = {
            'gRecaptchaResponse': data.recaptchaReactive,
            'Content-Type': 'application/json'
        };

        return this.http.post<String>(this.API_URL, payload, { headers: headers})
    }
}