import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {ApiService} from "hello_angular/app/services/api.service";

@Component({
  selector: 'hello-angular',
  template: require('./app.component.html')
})
export class AppComponent implements OnInit {
  public contactForm: FormGroup;
  private subscription: Subscription;

  public submitButtonDisabled = false;
  public result = 'hidden';
  public resultMessage;

  constructor(private fb: FormBuilder, private api: ApiService) {}

  ngOnInit() {
    this.contactForm = this.fb.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      subject: [null, Validators.required],
      message: [null, Validators.required],
      recaptchaReactive: [null, Validators.required]
    });
  }

  submitForm() {
    setTimeout(() => this.contactForm.disable(), 100);
      this.subscription = this.api.postContactForm(this.contactForm.value).subscribe((response: any) => {
      console.log("Contact Response: ", response);

      if(response.success) {
        this.submitButtonDisabled = true;
        this.resultMessage = response.message;
        this.result = 'alert alert-success';
      } else {
        this.submitButtonDisabled = false;
        this.resultMessage = response.message;
        this.result = 'alert alert-danger';
        setTimeout(() => this.contactForm.enable(), 100);
      }
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
